import { styled } from '@mui/material/styles';

const PREFIX = 'BreadcrumbHeader';

export const classes = {
  banner: `${PREFIX}-banner`,
  button: `${PREFIX}-button`,
  breadcrumb: `${PREFIX}-breadcrumb`,
  breadcrumbLink: `${PREFIX}-breadcrumbLink`,
};

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.banner}`]: {
    display: 'flex',
    backgroundColor: theme.palette.primary.secondary,
    width: '100%',
    maxWidth: '100%',
    '@media (max-width: 1140px)': {
      marginTop: '0px',
    },
  },
  [`& .${classes.button}`]: {
    color: theme.palette.primary.main,
    margin: '8px 0px',
    padding: '2px 8px 2px 4px',
  },
  [`& .${classes.breadcrumb}`]: {
    display: 'flex',
    marginLeft: '20px',
    color: theme.palette.primary.contrast,
    '& .MuiBreadcrumbs-separator': {
      color: theme.palette.primary.contrast,
    },
  },
  [`& .${classes.breadcrumbLink}`]: {
    '&:visited': {
      color: theme.palette.primary.contrast,
    },
  },
}));
