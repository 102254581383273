import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { clearSession, SESSION_TYPE } from 'services/sessionHistoryService';

import { auth } from '../../../firebaseApp';
import useStyles from './style';

export const LogoutSection = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [logoutMessage, setLogoutMessage] = useState(false);

  const logOut = () => {
    setLogoutMessage(true);
    navigate('/');
    setTimeout(handleLogout, 3000);
  };

  // TODO: Handle logout for for Google and basic login once we have combined the two
  const handleLogout = () => {
    window.location.reload();
    auth.signOut();
    sessionStorage.removeItem('isUserLoggedIn');
    clearSession({ sessionType: SESSION_TYPE.PREFS });
    setLogoutMessage(false);
  };

  const handleClose = () => setLogoutMessage(false);

  return (
    <div>
      <MenuItem onClick={logOut}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        Log out
      </MenuItem>
      <Modal
        open={logoutMessage}
        onClose={handleLogout}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modal}>
          <IconButton
            className={classes.close}
            aria-label="close"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <Typography className={classes.title} variant="h4">
            Thank you for visiting!
          </Typography>
          <Typography className={classes.note} variant="h6">
            You are now logged out of Skill-Up
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
