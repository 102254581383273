export const BASE_URL_1 =
  process.env.REACT_APP_API_URL !== undefined
    ? `${process.env.REACT_APP_API_URL}` // $REACT_APP_API_URL needs to be set in the production server environment
    : 'http://localhost:5002/'; // Default for development purposes

export const BASE_URL_2 =
  process.env.REACT_APP_API_V2_URL !== undefined
    ? `${process.env.REACT_APP_API_V2_URL}` // $REACT_APP_API_URL needs to be set in the production server environment
    : 'http://localhost:5004/'; // Default for development purposes

export const INDUSTRY_INDEX_URL =
  process.env.REACT_APP_INDUSTRY_INDEX_APP_URL !== undefined
    ? `${process.env.REACT_APP_INDUSTRY_INDEX_APP_URL}` // needs to be set in production server environment to redirect to the correct webpage
    : 'http://localhost:3000/industry-index'; // Default for development purposes. Just run `yarn start -p 3001` from the industry index app directory.

export const PHOTON_GRAPH_URL =
  process.env.REACT_APP_PHOTON_GRAPH_API_URL !== undefined
    ? `${process.env.REACT_APP_PHOTON_GRAPH_API_URL}` // needs to be set in production server environment to redirect to the correct webpage
    : 'http://backend:5000/photon/graphql'; // Default for development purposes. Just run `yarn start -p 3001` from the industry index app directory.

export const PUBLIC_PATH =
  process.env.PUBLIC_URL !== undefined
    ? process.env.PUBLIC_URL.substr(process.env.PUBLIC_URL.lastIndexOf('/')) // needs to be set in production server environment to redirect to the correct webpage
    : ''; // Default for development purposes. Just run `yarn start -p 3001` from the industry index app directory.
