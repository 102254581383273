import React from 'react';

import Grid from '@mui/material/Grid';

import JobTitleSearchMobile from './components/JobTitleSelector';
import OccupationSearchMobile from './components/OccupationSelector';
import CareerAreaSearchMobile from './components/CareerAreaSelector';
import RegionSearchMobile from './components/RegionSelector';
import DropdownSearchMobile from './components/DropdownSelector';

export const CombinedSearchDrawer = ({
  toggleSearchDrawer,
  searchType,
  careerAreaOptions,
  onCareerAreaChange,
  area,
  occupation,
  occupationOptions,
  sortedOccupationOptions,
  onOccupationChange,
  jobTitle,
  onJobTitleChange,
  currentUser,
  favourites,
  showFavourites,
  handleFavourites,
  app,
}) => {
  return (
    <Grid container spacing={2} flexDirection="column">
      <Grid item xs={12}>
        {searchType === 'careerArea' ? (
          <CareerAreaSearchMobile
            onChange={onCareerAreaChange}
            toggleSearchDrawer={toggleSearchDrawer}
            careerArea={area}
            careerAreaOptions={careerAreaOptions}
            app={app}
          />
        ) : searchType === 'occupation' ? (
          <OccupationSearchMobile
            toggleSearchDrawer={toggleSearchDrawer}
            careerAreaOptions={careerAreaOptions}
            onCareerAreaChange={onCareerAreaChange}
            careerArea={area}
            occupationOptions={occupationOptions}
            sortedOccupationOptions={sortedOccupationOptions}
            onOccupationChange={onOccupationChange}
            occupation={occupation}
            currentUser={currentUser}
            favourites={favourites}
            showFavourites={showFavourites}
            handleFavourites={handleFavourites}
            app={app}
          />
        ) : (
          searchType === 'jobTitle' && (
            <JobTitleSearchMobile
              toggleSearchDrawer={toggleSearchDrawer}
              onChange={onJobTitleChange}
              careerArea={area}
              occupation={occupation.name}
              jobTitle={jobTitle}
              app={app}
            />
          )
        )}
      </Grid>
    </Grid>
  );
};

export const CareerAreaSearchDrawer = ({
  toggleSearchDrawer,
  careerAreaOptions,
  onCareerAreaChange,
  area,
  app,
}) => {
  return (
    <Grid container spacing={2} flexDirection="column">
      <Grid item xs={12}>
        <CareerAreaSearchMobile
          onChange={onCareerAreaChange}
          toggleSearchDrawer={toggleSearchDrawer}
          careerArea={area}
          careerAreaOptions={careerAreaOptions}
          app={app}
        />
      </Grid>
    </Grid>
  );
};

export const RegionSearchDrawer = ({
  toggleSearchDrawer,
  regionOptions,
  onRegionChange,
  region,
  app,
}) => {
  return (
    <Grid container spacing={2} flexDirection="column">
      <Grid item xs={12}>
        <RegionSearchMobile
          toggleSearchDrawer={toggleSearchDrawer}
          regionOptions={regionOptions}
          onRegionChange={onRegionChange}
          region={region}
          app={app}
        />
      </Grid>
    </Grid>
  );
};

export const DropdownSearchDrawer = ({
  toggleSearchDrawer,
  options,
  onSelect,
  selectedValue,
  dataProvider,
  defaultOption,
  label,
  id,
  app,
  dropdownStyles,
  group,
}) => {
  return (
    <Grid container spacing={2} flexDirection="column">
      <Grid item xs={12}>
        <DropdownSearchMobile
          toggleSearchDrawer={toggleSearchDrawer}
          options={options}
          onSelect={onSelect}
          selectedValue={selectedValue}
          dataProvider={dataProvider}
          defaultOption={defaultOption}
          label={label}
          id={id}
          app={app}
          dropdownStyles={dropdownStyles}
          group={group}
        />
      </Grid>
    </Grid>
  );
};
