import React from 'react';
import { useLocation } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { Root, classes } from './style';
import { BackButton } from 'components/ui';

const pathToNameMapping = {
  home: 'Home',
  about: 'About',
  explore: 'Explore All Occupations',
  growth: 'Fastest Growing Occupations',
  demand: 'In Demand Occupations',
  'skills-explorer': 'Skills Explorer',
  'interests-quiz': 'Interests Quiz',
  'working-in-ab': 'Working In Alberta',
  favourites: 'My Favourites',
  privacy: 'Privacy Statement',
  terms: 'Terms Of Use',
};

export const Breadcrumb = () => {
  const { pathname } = useLocation();
  const current = pathname.split('/').filter(crumb => crumb !== '')[0];

  if (
    pathname === '/' ||
    pathname === '/home' ||
    pathname === '/login' ||
    pathname === '/register' ||
    pathname === '/forgot-password' ||
    pathname === '/admin' ||
    pathname.includes('reset_password')
  )
    return null;

  return (
    <Root className={classes.banner}>
      <Container sx={{ display: 'flex' }}>
        <BackButton />
        <Breadcrumbs
          className={classes.breadcrumb}
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Typography sx={{ fontWeight: 450 }}>
            {pathToNameMapping[current]}
          </Typography>
        </Breadcrumbs>
      </Container>
    </Root>
  );
};
