import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { useMediaQuery } from '@mui/material';

import { classes, Root } from './style.js';

import { LoginForm } from './login/index.tsx';
import { RegisterForm } from './register/index';
import { ForgotPassword } from './forgotPassword/index.jsx';

import useToken from 'useToken';
import { gql, useLazyQuery } from '@apollo/client';
import { PHOTON_GRAPH_URL } from 'environmentConfig.js';
import featureFlagManager, { FLAG } from 'modules/featureFlagManager';
import ReactGA from 'react-ga4';
import { ROUTE } from 'routes';
import { PAGES } from 'pages';
export const USER_LIST = gql`
  query GetUserList {
    userAccounts {
      edges {
        node {
          id
          email
        }
      }
    }
  }
`;

export const Login = ({
  formType = 'login',
  handleClose = undefined,
  navigateAdminSettings = false,
}) => {
  ReactGA.send({ hitType: 'pageview', page: ROUTE.LOGIN, title: PAGES.LOGIN });
  const navigate = useNavigate();
  const { token, setToken } = useToken();
  const [imageIsReady, setImageIsReady] = useState(false);
  const [errorMsg, showErrorMsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [form, setForm] = useState(formType);
  const [redirect, setRedirect] = useState(null);
  const [userList, setUserList] = useState([]);
  const [getUsersQuery] = useLazyQuery(USER_LIST, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const logoSource = '/assets/skill-up-logo-horizontal.svg';
  const image = new Image();
  image.src = logoSource;
  image.onload = () => {
    setImageIsReady(true);
  };
  useEffect(() => {
    getUsersQuery({
      onCompleted: data => {
        setUserList(
          data.userAccounts.edges.map(item => ({
            id: item.node.id,
            email: item.node.email,
          }))
        );
      },
    });
  }, [getUsersQuery]);

  // Redirect the user if they are authenticated
  useEffect(() => {
    if (token && featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) {
      navigate('/home');
    }
     
  }, []);

  useEffect(() => {
    if (token && handleClose) {
      handleClose();
    }
     
  }, [token]);

  useEffect(() => {
    setForm(formType);
  }, [formType]);

  const isSmallScreen = useMediaQuery(
    `(max-width:${localStorage.getItem('MD_SCREEN')})`
  );

  return (
    <Root className={classes.base}>
      <Box className={classes.boxMain}>
        {imageIsReady && !isSmallScreen && (
          <img
            src={logoSource}
            alt="SKILL-UP"
            style={{ height: '30px', marginBottom: '10px' }}
          />
        )}
        <Typography
          variant="h6"
          sx={{ marginBottom: '20px', textAlign: 'center' }}
        >
          {form === 'login'
            ? 'Log In'
            : form === 'register'
            ? 'Register For Access'
            : 'Forgot Password'}
        </Typography>
        {msg && (
          <Alert
            severity={errorMsg ? 'error' : 'info'}
            sx={{ marginBottom: '20px' }}
          >
            {msg}
          </Alert>
        )}
        {form === 'login' ? (
          <LoginForm
            setForm={setForm}
            setMsg={setMsg}
            showErrorMsg={showErrorMsg}
            redirect={redirect}
            setRedirect={setRedirect}
            hideLogin={setToken}
            navigateAdminSettings={navigateAdminSettings}
          />
        ) : form === 'register' ? (
          <RegisterForm
            setForm={setForm}
            setMsg={setMsg}
            showErrorMsg={showErrorMsg}
            users={userList}
            redirect={redirect}
            setRedirect={setRedirect}
            handleClose={handleClose}
          />
        ) : (
          form === 'forgot-password' && (
            <ForgotPassword
              setForm={setForm}
              setMsg={setMsg}
              errorMsg={errorMsg}
              showErrorMsg={showErrorMsg}
            />
          )
        )}
      </Box>
    </Root>
  );
};
