import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import useMediaQuery from '@mui/material/useMediaQuery';

import { classes, Form } from '../style.js';
import { Typography } from '@mui/material';

import bcrypt from 'bcryptjs';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import featureFlagManager, { FLAG } from 'modules/featureFlagManager';
import { PHOTON_GRAPH_URL } from 'environmentConfig';
import PasswordInput from '../components/PasswordInput';
import useEmailValidation from 'hooks/useEmailValidation.js';
import { signInWithGooglePopup } from '../../../firebaseApp';
import ReactGA from 'react-ga4';
import { ROUTE } from 'routes';
import { PAGES } from 'pages';

const updateUserData = gql`
  mutation INCREMENT_USER_COUNT($email: String!) {
    incrementCount(input: { _email: $email }) {
      clientMutationId
    }
    updateLastLogInDate(input: { _email: $email }) {
      clientMutationId
    }
  }
`;

const isValidUser = gql`
  query GET_USER($email: String!) {
    userAccounts(filter: { email: { equalToInsensitive: $email } }) {
      nodes {
        id
        email
        firstname
        lastname
        photoUrl
        password
        expired
      }
    }
  }
`;

const getUserById = gql`
  query GET_USER_BY_ID($id: String!) {
    userAccount(id: $id) {
      id
      email
      firstname
      lastname
      photoUrl
      expired
      approved
    }
  }
`;

const UPDATE_EMAIL = gql`
  mutation UpdateEmail($id: String!, $email: String) {
    updateEmail(input: { _id: $id, _email: $email }) {
      affectedrows
    }
  }
`;

const UPDATE_FIELD = gql`
  mutation UpdateField($email: String!, $field: String, $value: String) {
    updateField(
      input: { _email: $email, _fieldName: $field, _fieldValue: $value }
    ) {
      boolean
    }
  }
`;

const getRoles = gql`
  query GET_ROLES($id: String!) {
    userRoleMappings(condition: { userId: $id }) {
      nodes {
        role {
          role
        }
      }
    }
  }
`;

export const LoginForm = ({
  setForm,
  setMsg,
  showErrorMsg,
  redirect = null,
  setRedirect,
  hideLogin,
  navigateAdminSettings = false,
}) => {
  ReactGA.send({ hitType: 'pageview', page: ROUTE.LOGIN, title: PAGES.LOGIN });
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const { email, setEmail, validateEmail } = useEmailValidation('', null);

  const [checkAccount] = useLazyQuery(getUserById, {
    context: {
      uri: PHOTON_GRAPH_URL,
    },
    fetchPolicy: 'network-only',
  });

  const [accountQuery] = useLazyQuery(isValidUser, {
    variables: { email: email.toLowerCase() },
    context: {
      uri: PHOTON_GRAPH_URL,
    },
  });

  const [getUserRoles] = useLazyQuery(getRoles, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const [updateLoginFrequency] = useMutation(updateUserData, {
    context: {
      uri: PHOTON_GRAPH_URL,
    },
  });

  const [updateEmail] = useMutation(UPDATE_EMAIL, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const [updateField] = useMutation(UPDATE_FIELD, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const isSmallScreen = useMediaQuery(
    `(max-width:${localStorage.getItem('MD_SCREEN')})`
  );

  const handleForm = type => {
    if (
      isSmallScreen &&
      featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)
    ) {
      navigate('/' + type);
    }
    setForm(type);
    setPassword('');
    setEmail('');
    setMsg('');
    showErrorMsg(false);
  };

  useEffect(() => {
    if (redirect) {
      handleGoogleLogin(redirect);
    }
  }, []);

  useEffect(() => {
    validateEmail();
  }, [email]);

  const login = (user, roleList) => {
    const currentUser = {
      uid: user.id,
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      photoUrl: user.photoUrl,
      admin: roleList.includes('admin') || roleList.includes('superuser'),
      superuser: roleList.includes('superuser'),
    };
    updateLoginFrequency({ variables: { email: currentUser.email } });
    hideLogin(currentUser);
    window.dispatchEvent(new Event('login'));
  };

  const handleLogin = async event => {
    event.preventDefault();
    const result = await accountQuery({ variables: { email: email } });
    const roleList = [];
    if (result.data.userAccounts.nodes.length > 0) {
      const { expired } = result.data.userAccounts.nodes[0];
      const roles = await getUserRoles({
        variables: { id: result.data.userAccounts.nodes[0].id },
      });
      if (roles.data.userRoleMappings.nodes.length > 0) {
        for (let i = 0; i < roles.data.userRoleMappings.nodes.length; i++) {
          roleList.push(roles.data.userRoleMappings.nodes[i].role.role);
        }
      }
      if (expired && new Date(expired).getTime() <= new Date().getTime()) {
        if (!featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) {
          const dt = new Date();
          updateField({
            variables: {
              email: email,
              field: 'expired',
              value: new Date(dt.setMonth(dt.getMonth() + 1))
                .toISOString()
                .split('T')[0],
            },
          });
        } else {
          setMsg(
            'This account has expired. If you require an extension or have any questions please contact us at skillup@cybera.ca'
          );
          showErrorMsg(true);
          return;
        }
      }
      bcrypt
        .compare(password, result.data.userAccounts.nodes[0].password)
        .then(res => {
          if (res) {
            if (roleList.includes('admin') || roleList.includes('superuser')) {
              login(result.data.userAccounts.nodes[0], roleList);
            } else {
              login(result.data.userAccounts.nodes[0], roleList);
            }
            if (
              (roleList.includes('admin') || roleList.includes('superuser')) &&
              navigateAdminSettings
            ) {
              navigate('/admin');
            } else if (featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) {
              navigate('/home');
            }
          } else {
            setMsg('Invalid Email or Password. Please try again.');
            showErrorMsg(true);
          }
        })
        .catch(err => console.error(err.message));
    } else {
      setMsg('Invalid Email or Password. Please try again.');
      showErrorMsg(true);
    }
  };

  const handleGoogleLogin = async redirected => {
    // Handle case if user is redirected from registration
    let response;
    if (redirected) {
      response = redirected;
    } else {
      response = await signInWithGooglePopup();
    }

    if (response) {
      const result = await checkAccount({ variables: { id: response.uid } });

      if (result.data.userAccount) {
        const { expired, approved, firstname, lastname, email, photoUrl } =
          result.data.userAccount;

        if (expired && new Date(expired).getTime() <= new Date().getTime()) {
          if (!featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) {
            const dt = new Date();
            updateField({
              variables: {
                email: email,
                field: 'expired',
                value: new Date(dt.setMonth(dt.getMonth() + 1))
                  .toISOString()
                  .split('T')[0],
              },
            });
          } else {
            setMsg(
              'This account has expired. If you require an extension or have any questions please contact us at skillup@cybera.ca'
            );
            showErrorMsg(true);
            return;
          }
        }

        // Handle case where existing oAuth users do not have information stored in database
        if (!email) {
          updateEmail({
            variables: {
              id: response.uid,
              email: response.email,
            },
          });
        }

        if (!firstname || !lastname) {
          updateField({
            variables: {
              email: response.email,
              field: 'firstname',
              value: response.displayName.split(' ')[0],
            },
          });

          updateField({
            variables: {
              email: response.email,
              field: 'lastname',
              value: response.displayName.split(' ')[1],
            },
          });
        }

        if (!photoUrl) {
          updateField({
            variables: {
              email: response.email,
              field: 'photo_url',
              value: response.photoURL,
            },
          });
        }

        const roles = await getUserRoles({
          variables: { id: result.data.userAccount.id },
        });
        const roleList = [];
        if (roles.data.userRoleMappings.nodes.length > 0) {
          for (let i = 0; i < roles.data.userRoleMappings.nodes.length; i++) {
            roleList.push(roles.data.userRoleMappings.nodes[i].role.role);
          }
        }

        if (
          approved ||
          !featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)
        ) {
          login(result.data.userAccount, roleList);
          if (
            (roleList.includes('admin') || roleList.includes('superuser')) &&
            navigateAdminSettings
          ) {
            navigate('/admin');
          } else if (featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) {
            navigate('/home');
          }
        } else if (approved === false) {
          setMsg(
            'We regret to inform you that your registration request has been rejected. If you have any questions please contact us at skillup@cybera.ca'
          );
          showErrorMsg(true);
          return;
        } else {
          setMsg(
            'This account is awaiting approval. If you have any questions please contact us at skillup@cybera.ca'
          );
          showErrorMsg(true);
          return;
        }
      } else {
        // Redirect user to registration
        setRedirect(response);
        handleForm('register');
      }
    }
  };

  return (
    <Form className={classes.container}>
      <form>
        <TextField
          required
          value={email}
          label="Email"
          placeholder="Email"
          autoComplete="username"
          inputProps={{ inputMode: 'email' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          style={{ marginBottom: '20px', width: '100%' }}
          autoFocus={true}
          onChange={event => {
            setEmail(event.target.value);
            setMsg('');
            showErrorMsg(false);
          }}
          onKeyUp={eve => {
            if (eve.key === 'Enter') handleLogin(eve);
          }}
        />
        <PasswordInput
          label="Password"
          placeholder="Password"
          style={{ width: '100%' }}
          onChange={event => {
            setPassword(event.target.value);
            setMsg('');
            showErrorMsg(false);
          }}
          onKeyPress={eve => {
            if (eve.key === 'Enter') handleLogin(eve);
          }}
          tooltipIcons={{
            passwordLengthIcon: null,
            passwordLowercaseIcon: null,
            passwordUppercaseIcon: null,
            passwordNumberIcon: null,
            passwordSpecialCharacterIcon: null,
          }}
          passwordReset={false}
        />
      </form>
      <Button
        variant="contained"
        style={{ marginTop: '20px' }}
        onClick={handleLogin}
      >
        Log in
      </Button>
      <Grid
        container
        className={classes.textContainer}
        style={{ margin: '20px 0px' }}
      >
        <Typography
          className={classes.linkText}
          onClick={() => handleForm('forgot-password')}
        >
          Forgot password?
        </Typography>
      </Grid>
      <Divider>or</Divider>
      <Button
        variant="outlined"
        className={classes.googleButton}
        onClick={() => handleGoogleLogin(null)}
      >
        <img
          alt=""
          src="/assets/google-logo.png"
          style={{ marginRight: '5px' }}
          height={20}
          width={20}
        />
        Continue With Google
      </Button>
      <Button variant="text" onClick={() => handleForm('register')}>
        Don't have an account? Register for Access
      </Button>
    </Form>
  );
};
