import React, { useContext, useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { LoginModalContext } from 'modules/loginModalContext/LoginModal';

import { classes, MobileSearchGrid } from './style';

const OccupationSearchMobile = ({
  toggleSearchDrawer,
  currentUser,
  occupationOptions,
  sortedOccupationOptions,
  occupation,
  onOccupationChange,
  favourites,
  showFavourites,
  handleFavourites,
  careerArea,
  app,
}) => {
  const { openLoginModal } = useContext(LoginModalContext);

  const [inputValue, setInputValue] = useState(occupation.name);

  useEffect(() => {
    setInputValue(occupation.name);
  }, [occupation]);

  const formatResult = occ => {
    if (inputValue.length > 0) {
      const matches = match(occ, inputValue, {
        insideWords: true,
        requireMatchAll: true,
      });

      const parts = parse(occ, matches);

      return parts.map((part, index) => (
        <span
          key={index}
          style={{
            fontWeight: part.highlight ? 700 : 400,
            whiteSpace: 'pre',
          }}
        >
          {part.text}
        </span>
      ));
    }

    return occ;
  };

  const filteredOccupations = showFavourites
    ? sortedOccupationOptions
        .filter(({ name }) =>
          name.toLowerCase().includes(inputValue.toLowerCase())
        )
        .slice(0, 50)
    : occupationOptions
        .filter(({ name }) =>
          name.toLowerCase().includes(inputValue.toLowerCase())
        )
        .slice(0, 50);

  return (
    <MobileSearchGrid container>
      <Grid item xs={12} className={classes.header}>
        <Grid container className={classes.searchBar}>
          <Grid item xs={2} sm={1} className={classes.backButton}>
            <IconButton
              color={app}
              aria-label="Back"
              onClick={() => toggleSearchDrawer(false)}
              sx={{ width: '100%' }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} sm={11}>
            <Autocomplete
              id="occupationField"
              hiddenlabel="true"
              freeSolo
              forcePopupIcon={false}
              open
              options={
                showFavourites ? sortedOccupationOptions : occupationOptions
              }
              getOptionLabel={option => option.name}
              style={{
                flexGrow: 1,
                marginRight: '0px',
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                // Only set input value when user types
                if (event !== null && event.type !== 'blur') {
                  setInputValue(newInputValue);
                }
              }}
              PaperComponent={props => (
                <Paper
                  sx={{
                    display: 'none',
                  }}
                  {...props}
                />
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder={'Search occupations in ' + careerArea}
                  inputRef={input => input && input.focus()}
                  variant="outlined"
                  color={app}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'default',
                      },
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      borderRadius: '12px',
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider />
        <Paper>
          {!currentUser && (
            <>
              <Box
                onMouseDown={e => e.preventDefault()} // prevent blur
                pl={1.5}
                py={1.5}
                sx={{ marginRight: '10px' }}
              >
                <Button
                  size="small"
                  color={app}
                  variant={'outlined'}
                  onMouseDown={e => {
                    e.preventDefault(); // prevent blur
                    openLoginModal('login');
                  }}
                >
                  Login to save/search favourite occupations
                </Button>
              </Box>
              <Divider />
            </>
          )}
          {currentUser && favourites?.length > 0 && (
            <>
              <Box
                onMouseDown={e => e.preventDefault()} // prevent blur
                pl={1.5}
                py={1.5}
              >
                <Button
                  size="small"
                  color={app}
                  sx={{ color: showFavourites ? '#fff' : '' }}
                  variant={showFavourites ? 'contained' : 'outlined'}
                  startIcon={showFavourites ? <CheckIcon /> : null}
                  onMouseDown={e => {
                    e.preventDefault(); // prevent blur
                    handleFavourites();
                  }}
                >
                  Show favourites at top
                </Button>
              </Box>
              <Divider />
            </>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {filteredOccupations.map(value => (
          <Box
            key={value.name + '-' + value.area}
            className={classes.result}
            onClick={() => {
              onOccupationChange(value, 'selectOption');
              toggleSearchDrawer(false);
            }}
          >
            <SearchIcon className={classes.searchIcon} />
            {formatResult(value.name)}
            {favourites?.some(occupation => occupation.name === value.name) && (
              <FavoriteIcon
                fontSize="small"
                sx={{
                  marginLeft: '5px',
                  color: '#E75B5B',
                }}
              />
            )}
          </Box>
        ))}
      </Grid>
    </MobileSearchGrid>
  );
};

export default OccupationSearchMobile;
