import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_PROJECT_DOMAIN,
};

export const app = initializeApp(config);
export const auth = getAuth();

const provider = new GoogleAuthProvider();
provider.addScope('profile');
provider.addScope('email');
provider.setCustomParameters({
  prompt: 'select_account ',
});

export const signInWithGooglePopup = () =>
  signInWithPopup(auth, provider)
    .then(result => {
      const { user } = result;
      user.email = user.providerData[0].email;
      return user;
    })
    .catch(error => {
      console.log('Error authenticating with Google:', error);
    });
