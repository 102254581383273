import { ApolloClient, ApolloError, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
// import { usePregeneratedHashes as withPregeneratedHashes } from 'graphql-codegen-persisted-query-ids/lib/apollo';

import { PHOTON_GRAPH_URL } from './environmentConfig';
// import hashes from './clientPersistedQueries.json';

import { onError } from '@apollo/client/link/error';

const createApolloClient = token => {
  const uploadLink = createUploadLink({
    uri: PHOTON_GRAPH_URL,
    options: {
      reconnect: true,
    },
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  // const persistedQueryLink = createPersistedQueryLink({
  //   generateHash: withPregeneratedHashes(hashes),
  //   useGETForHashedQueries: false,
  //   disable: () => false,
  // });

  // Log any GraphQL errors or Apollo network error that occurred
  // Note: The errors are passed through to the query/mutation that initiated
  // the graphql request. These should be handled within the query/mutation.
  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        console.error(
          `Operation: ${operation.operationName}, Message: ${message}`
        );
      });
    }
    if (networkError) {
      throw new ApolloError(`Network error: ${networkError}`);
    }
  });

  return new ApolloClient({
    link: from([errorLink, authLink, uploadLink]),
    cache: new InMemoryCache(),
  });
};

export default createApolloClient;
