import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';

import { useMediaQuery } from '@mui/material';

import { classes, ResetContainer } from '../style.js';

import bcrypt from 'bcryptjs';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { PHOTON_GRAPH_URL } from 'environmentConfig';

import { validateToken } from 'services/passwordResetService.js';
import PasswordInput from '../components/PasswordInput';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ReactGA from 'react-ga4';
import { ROUTE } from 'routes';
import { PAGES } from 'pages';
const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!, $password: String!) {
    updateField(
      input: { _email: $email, _fieldName: "password", _fieldValue: $password }
    ) {
      boolean
    }
  }
`;

const isValidUser = gql`
  query GET_USER($email: String!) {
    userAccountByEmail(email: $email) {
      password
    }
  }
`;

export const ResetPassword = (): JSX.Element => {
  ReactGA.send({ hitType: 'pageview', page: ROUTE.RESET_PASSWORD, title: PAGES.RESET_PASSWORD });
  const navigate = useNavigate();
  const { email, token } = useParams();

  const [validLink, setValidLink] = useState(true);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [imageIsReady, setImageIsReady] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsg, showErrorMsg] = useState(false);
  const [msg, setMsg] = useState('');

  const [passwordLengthIcon, setPasswordLengthIcon] = useState(
    <CancelIcon style={{ color: 'red', paddingRight: '10px' }} />
  );
  const [passwordLowercaseIcon, setPasswordLowercaseIcon] = useState(
    <CancelIcon style={{ color: 'red', paddingRight: '10px' }} />
  );
  const [passwordUppercaseIcon, setPasswordUppercaseIcon] = useState(
    <CancelIcon style={{ color: 'red', paddingRight: '10px' }} />
  );
  const [passwordNumberIcon, setPasswordNumberIcon] = useState(
    <CancelIcon style={{ color: 'red', paddingRight: '10px' }} />
  );
  const [
    passwordSpecialCharacterIcon,
    setPasswordSpecialCharacterIcon,
  ] = useState(<CancelIcon style={{ color: 'red', paddingRight: '10px' }} />);
  const [passwordLengthConfirmIcon, setPasswordLengthConfirmIcon] = useState(
    <CancelIcon style={{ color: 'red', paddingRight: '10px' }} />
  );
  const [
    passwordLowercaseConfirmIcon,
    setPasswordLowercaseConfirmIcon,
  ] = useState(<CancelIcon style={{ color: 'red', paddingRight: '10px' }} />);
  const [
    passwordUppercaseConfirmIcon,
    setPasswordUppercaseConfirmIcon,
  ] = useState(<CancelIcon style={{ color: 'red', paddingRight: '10px' }} />);
  const [passwordNumberConfirmIcon, setPasswordNumberConfirmIcon] = useState(
    <CancelIcon style={{ color: 'red', paddingRight: '10px' }} />
  );
  const [
    passwordSpecialCharacterConfirmIcon,
    setPasswordSpecialCharacterConfirmIcon,
  ] = useState(<CancelIcon style={{ color: 'red', paddingRight: '10px' }} />);
  const [resetButtonDisable, setResetButtonDisable] = useState(true);
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    context: { uri: PHOTON_GRAPH_URL },
  });

  const [accountQuery] = useLazyQuery(isValidUser, {
    variables: { email: email.toLowerCase() },
    context: {
      uri: PHOTON_GRAPH_URL,
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const result = await accountQuery({ variables: { email: email } });
        if (result.data.userAccountByEmail) {
          const { password } = result.data.userAccountByEmail;
          const isValidLink = await validateToken(email, password, token);
          if (!isValidLink) {
            setValidLink(false);
          }
        }
      } catch (error) {
        console.log(error);
        setValidLink(false);
      }
    })();
    setValidLink(true);
     
  }, []);

  const logoSource = '/assets/skill-up-logo-horizontal.svg';
  const image = new Image();
  image.src = logoSource;
  image.onload = () => {
    setImageIsReady(true);
  };

  const handleResetPassword = async event => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setMsg('Passwords do not match.');
      showErrorMsg(true);
      return;
    }
    // Hash and update the password for the user
    const hash = await bcrypt.hash(password, 10);
    resetPassword({
      variables: { email: email, password: hash },
    })
      .then(res => {
        if (res.data.updateField.boolean === true) {
          setMsg('Password reset completed successfully!');
          setResetSuccess(true);
        } else {
          setMsg('Password Reset Failed');
          showErrorMsg(true);
        }
      })
      .catch(error => {
        console.error(error);
        setMsg(error.message);
        showErrorMsg(true);
      });
  };

  const validatePassword = (passwordInput, type) => {
    const checkIcon = (
      <CheckCircleIcon style={{ color: 'green', paddingRight: '10px' }} />
    );
    const xIcon = <CancelIcon style={{ color: 'red', paddingRight: '10px' }} />;
    let count = 0;
    if (type === 'new') {
      if (passwordInput.length >= 8) {
        setPasswordLengthIcon(checkIcon);
        count++;
      } else {
        setPasswordLengthIcon(xIcon);
      }
      if (passwordInput.toUpperCase() !== passwordInput) {
        setPasswordLowercaseIcon(checkIcon);
        count++;
      } else {
        setPasswordLowercaseIcon(xIcon);
      }
      if (passwordInput.toLowerCase() !== passwordInput) {
        setPasswordUppercaseIcon(checkIcon);
        count++;
      } else {
        setPasswordUppercaseIcon(xIcon);
      }
      if (/\d/.test(passwordInput)) {
        setPasswordNumberIcon(checkIcon);
        count++;
      } else {
        setPasswordNumberIcon(xIcon);
      }
      // eslint-disable-next-line
      if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(passwordInput)) {
        setPasswordSpecialCharacterIcon(checkIcon);
        count++;
      } else {
        setPasswordSpecialCharacterIcon(xIcon);
      }
      if (count === 5) {
        setNewPasswordValid(true);
      } else {
        setNewPasswordValid(false);
      }
    } else {
      if (passwordInput.length >= 8) {
        setPasswordLengthConfirmIcon(checkIcon);
        count++;
      } else {
        setPasswordLengthConfirmIcon(xIcon);
      }
      if (passwordInput.toUpperCase() !== passwordInput) {
        setPasswordLowercaseConfirmIcon(checkIcon);
        count++;
      } else {
        setPasswordLowercaseConfirmIcon(xIcon);
      }
      if (passwordInput.toLowerCase() !== passwordInput) {
        setPasswordUppercaseConfirmIcon(checkIcon);
        count++;
      } else {
        setPasswordUppercaseConfirmIcon(xIcon);
      }
      if (/\d/.test(passwordInput)) {
        setPasswordNumberConfirmIcon(checkIcon);
        count++;
      } else {
        setPasswordNumberConfirmIcon(xIcon);
      }
      //eslint-disable-next-line
      if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(passwordInput)) {
        setPasswordSpecialCharacterConfirmIcon(checkIcon);
        count++;
      } else {
        setPasswordSpecialCharacterConfirmIcon(xIcon);
      }
      if (count === 5) {
        setConfirmPasswordValid(true);
      } else {
        setConfirmPasswordValid(false);
      }
    }
  };

  useEffect(() => {
    if (newPasswordValid && confirmPasswordValid) {
      setResetButtonDisable(false);
    } else {
      setResetButtonDisable(true);
    }
  }, [newPasswordValid, confirmPasswordValid]);

  const isSmallScreen = useMediaQuery(
    `(max-width:${localStorage.getItem('MD_SCREEN')})`
  );

  return (
    <ResetContainer className={classes.resetContainer}>
      {validLink ? (
        <Box className={classes.boxMain}>
          {imageIsReady && !isSmallScreen && (
            <img
              src={logoSource}
              alt="SKILL-UP"
              style={{ height: '50px', marginBottom: '20px' }}
            />
          )}
          {resetSuccess ? (
            <>
              <Typography variant="h6">Success!</Typography>
              <Typography
                variant="body2"
                sx={{ marginBottom: '10px', color: 'grey' }}
              >
                Your password has been updated successfully. You can now log in
                to your account using your new password.
              </Typography>
              <Button
                variant="contained"
                className={classes.resetButton}
                onClick={() => navigate('/home')}
              >
                Log in
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h6">Reset your password</Typography>
              <Typography
                variant="body2"
                sx={{ marginBottom: '20px', color: 'grey' }}
              >
                To ensure the security of your account, please choose a strong
                password.
              </Typography>
              {msg && (
                <Alert
                  severity={errorMsg ? 'error' : 'info'}
                  sx={{ marginBottom: '20px' }}
                >
                  {msg}
                </Alert>
              )}
              <form>
                <PasswordInput
                  label="New Password"
                  placeholder="New Password"
                  style={{ marginBottom: '20px', width: '100%' }}
                  onChange={event => {
                    validatePassword(event.target.value, 'new');
                    setPassword(event.target.value);
                    showErrorMsg(false);
                  }}
                  onKeyPress={eve => {
                    if (eve.key === 'Enter') handleResetPassword(eve);
                  }}
                  tooltipIcons={{
                    passwordLengthIcon: passwordLengthIcon,
                    passwordLowercaseIcon: passwordLowercaseIcon,
                    passwordUppercaseIcon: passwordUppercaseIcon,
                    passwordNumberIcon: passwordNumberIcon,
                    passwordSpecialCharacterIcon: passwordSpecialCharacterIcon,
                  }}
                  passwordReset={true}
                />
                <PasswordInput
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  style={{ width: '100%' }}
                  onChange={event => {
                    validatePassword(event.target.value, 'confirm');
                    setConfirmPassword(event.target.value);
                    showErrorMsg(false);
                  }}
                  onKeyPress={eve => {
                    if (eve.key === 'Enter') handleResetPassword(eve);
                  }}
                  tooltipIcons={{
                    passwordLengthIcon: passwordLengthConfirmIcon,
                    passwordLowercaseIcon: passwordLowercaseConfirmIcon,
                    passwordUppercaseIcon: passwordUppercaseConfirmIcon,
                    passwordNumberIcon: passwordNumberConfirmIcon,
                    passwordSpecialCharacterIcon: passwordSpecialCharacterConfirmIcon,
                  }}
                  passwordReset={true}
                />
              </form>
              <Button
                variant="contained"
                className={classes.resetButton}
                onClick={handleResetPassword}
                disabled={resetButtonDisable}
              >
                Reset Password
              </Button>
            </>
          )}
        </Box>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          {imageIsReady && (
            <img
              src={logoSource}
              alt="SKILL-UP"
              style={{ width: isSmallScreen ? '100%' : '400px' }}
            />
          )}
          <Typography
            variant="h6"
            style={{ margin: '20px auto', maxWidth: 800 }}
          >
            This link is expired. If you would like to reset your password
            please make another reset password request.
          </Typography>
          <Button
            variant="contained"
            className={classes.resetButton}
            onClick={() => navigate('/')}
          >
            Back to SkillUp
          </Button>
        </div>
      )}
    </ResetContainer>
  );
};
