import emailjs from '@emailjs/browser';
import {
  EXPIRED_ACCOUNT_TEMPLATE_ID,
  REJECTION_EMAIL_TEMPLATE_ID,
  APPROVAL_EMAIL_TEMPLATE_ID,
  RESET_PASSWORD_TEMPLATE_ID,
  USER_REGISTRATION_ALERT_TEMPLATE_ID,
  WELCOME_EMAIL_TEMPLATE_ID,
} from 'data/defaults/emailJsTemplates';

// env variables
const emailServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const emailjsAuthKey = process.env.REACT_APP_EMAILJS_KEY;
const generateResetToken = async user => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_PUBLIC_URL}/generate-reset-token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      }
    );

    if (response.ok) {
      const result = await response.json();
      return result.link;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const sendResetPasswordEmail = async (user, initial = false) => {
  try {
    const { firstname, email, password } = user;
    const token = await generateResetToken({
      email: email.toLowerCase(),
      password,
    });

    if (!token) {
      throw new Error('Error Generating reset password token');
    }

    // Use emailjs to send the email

    const emailParams = {
      name: firstname,
      reset_link: token,
      email: email.toLowerCase(),
    };

    await emailjs.send(
      emailServiceId,
      initial ? WELCOME_EMAIL_TEMPLATE_ID : RESET_PASSWORD_TEMPLATE_ID,
      emailParams,
      emailjsAuthKey
    );

    return { success: true, message: 'Reset password email sent successfully' };
  } catch (error) {
    console.error(error);
    return { success: false, message: 'Error sending reset password email' };
  }
};

export const validateToken = async (username, hash, token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_PUBLIC_URL}/validate-reset-token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, hash, token }),
      }
    );
    const data = await response.json();

    if (data.success === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error validating reset password token:', error);
    return false;
  }
};

export const sendAccountExpiredEmail = async user => {
  try {
    const { username, email } = user;

    const emailParams = {
      username, // This will be filled with user's firstname
      email: email.toLowerCase(),
    };

    await emailjs.send(
      emailServiceId,
      EXPIRED_ACCOUNT_TEMPLATE_ID,
      emailParams,
      emailjsAuthKey
    );
  } catch (error) {
    console.log(error);
  }
};

export const sendApprovalEmail = async user => {
  try {
    const { firstname, email } = user;
    const emailParams = {
      name: firstname,
      email: email.toLowerCase(),
    };

    await emailjs.send(
      emailServiceId,
      APPROVAL_EMAIL_TEMPLATE_ID,
      emailParams,
      emailjsAuthKey
    );
  } catch (error) {
    console.log(error);
  }
};

export const sendRejectionEmail = async user => {
  try {
    const { firstname, email } = user;
    const emailParams = {
      name: firstname,
      email: email.toLowerCase(),
    };

    await emailjs.send(
      emailServiceId,
      REJECTION_EMAIL_TEMPLATE_ID,
      emailParams,
      emailjsAuthKey
    );
  } catch (error) {
    console.log(error);
  }
};

export const sendUserRegistrationAlert = async user => {
  try {
    const { firstname, lastname, email, institution } = user;
    const emailParams = {
      name: firstname + ' ' + lastname,
      user_email: email.toLowerCase(),
      institution: institution,
      email: process.env.REACT_APP_ADMIN_EMAIL,
    };

    await emailjs.send(
      emailServiceId,
      USER_REGISTRATION_ALERT_TEMPLATE_ID,
      emailParams,
      emailjsAuthKey
    );
  } catch (error) {
    console.log(error);
  }
};
