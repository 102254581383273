import React, { useCallback, useState, useContext, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

import useMediaQuery from '@mui/material/useMediaQuery';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { AuthContext } from 'Auth';
import { LoginModalContext } from 'modules/loginModalContext/LoginModal';

import { classes, SignInPopover } from './style';

// Default position of this component is to show a prompt when a user is not logged in,
// however if you provide the props showOnExit = true then the component will show the
// prompt only when the user is navigating away from the current tool (ie Interests Quiz)
// - toggle prop is used to show the prompt more often as needed by the parent component.
// - onClose prop is used to perform action by parent when popup is closed if needed.

export const SignInPrompt = ({
  showOnExit = false,
  toggle = false,
  onClose = false,
  children,
}) => {
  const { currentUser } = useContext(AuthContext);
  const { openLoginModal } = useContext(LoginModalContext);
  const [open, setOpen] = useState(false);

  let shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      if (showOnExit) {
        return (
          !currentUser &&
          currentLocation.pathname.split('/')[1] !==
            nextLocation.pathname.split('/')[1]
        );
      }
      return false;
    },
    [currentUser, showOnExit]
  );

  let blocker = useBlocker(shouldBlock);

  const width = window.screen.width;

  const isMobile = useMediaQuery(
    `(max-width:${localStorage.getItem('SM_SCREEN')})`
  );

  useEffect(() => {
    if (blocker.state === 'blocked' || (!showOnExit && !currentUser)) {
      setOpen(true);
    }
  }, [blocker, showOnExit, currentUser, toggle]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleSubmit = () => {
    setOpen(false);
    openLoginModal('login');
  };

  const handleDiscard = () => blocker.proceed();

  return (
    <>
      <SignInPopover
        anchorReference="anchorPosition"
        anchorPosition={{ top: 110, left: isMobile ? 0 : width }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        elevation={10}
      >
        <Card className={classes.promptBody} elevation={0}>
          <CardHeader
            className={classes.promptHeader}
            action={
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent className={classes.promptContent}>
            {children}
            <Typography
              className={children ? null : classes.promptContentNoChildren}
            >
              Want to store your saved items, preferences and make your career
              exploration easier?
            </Typography>
          </CardContent>
          <CardActions className={classes.promptActions}>
            {showOnExit && (
              <Button
                className={classes.promptDiscardButton}
                variant="outlined"
                onClick={handleDiscard}
              >
                Continue
              </Button>
            )}
            <Button
              className={classes.promptButton}
              variant="contained"
              onClick={handleSubmit}
            >
              Log In
            </Button>
          </CardActions>
        </Card>
      </SignInPopover>
    </>
  );
};
