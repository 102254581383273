import React, {
  useMemo,
  useEffect,
  useContext,
  Suspense,
  useState,
} from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
  Navigate,
} from 'react-router-dom';

import { gql, useQuery, ApolloProvider } from '@apollo/client';
import createApolloClient from './apolloClient';

import { useMediaQuery, CssBaseline } from '@mui/material';

import { LoginModalProvider } from 'modules/loginModalContext/LoginModal';
import { ModalSpinner } from './components/ui/spinners/ModalSpinner';
import ErrorBoundary from './ErrorBoundary';
import { Maintenance } from 'apps/maintenance';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import ReactGA from 'react-ga4';

import { PUBLIC_PATH, PHOTON_GRAPH_URL } from './environmentConfig';
import { ROUTE } from './routes';

import Header from 'components/layout/header';
import Footer from 'components/layout/footer/Footer';
import featureFlagManager, { FLAG } from './modules/featureFlagManager';
import { AuthContext } from './Auth';
import useToken from 'useToken';
import ScrollToTop from 'scrollToTop';
import { ResetPassword } from 'apps/Login/resetPassword';
import { Login } from 'apps/Login/Login';

const LandingScene = React.lazy(() => import('./apps/landingPage'));
const AboutScene = React.lazy(() => import('./apps/about'));
const HomeApp = React.lazy(() => import('./apps/home'));
const SkillsExplorerApp = React.lazy(() => import('./apps/skillsExplorer'));
const ExploreScene = React.lazy(
  () => import('./apps/industryIndex/scenes/explore')
);
const GrowthScene = React.lazy(
  () => import('./apps/industryIndex/scenes/growth')
);
const DemandScene = React.lazy(
  () => import('./apps/industryIndex/scenes/demand')
);
const TrainingScene = React.lazy(
  () => import('./apps/industryIndex/scenes/training')
);
const WorkingInAlbertaApp = React.lazy(() => import('./apps/workingInAlberta'));
const InterestFilterApp = React.lazy(() => import('./apps/interestFilter'));
const AdminHomeScene = React.lazy(() => import('./apps/admin/scenes/home'));
const FavouritesScene = React.lazy(() => import('./apps/favourites'));
const PrivacyStatement = React.lazy(
  () => import('./components/layout/footer/links/PrivacyStatement')
);
const TermsOfUse = React.lazy(
  () => import('./components/layout/footer/links/TermsOfUse')
);

const ReleaseNotesPage = React.lazy(
  () => import('./components/layout/footer/links/ReleaseNotes')
);
const defaultStyle = {
  flex: '1 0 auto',
};

const APP_CONFIG = gql`
  query App {
    themes {
      nodes {
        value
      }
    }
    featureFlags {
      nodes {
        feature
        isActive
      }
    }
    lastUpdated
  }
`;

const mapImageSource = '/assets/landingPage/canada.png';
const image = new Image();
image.src = mapImageSource;

const BaseApp = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    setDarkMode(prefersDarkMode);
  }, [prefersDarkMode]);

  const { loading, data } = useQuery(APP_CONFIG, {
    context: {
      uri: PHOTON_GRAPH_URL,
    },
  });

  const theme = useMemo(() => {
    if (loading || !data) {
      // Return a default theme or null while loading
      return createTheme();
    }
    return createTheme(
      darkMode ? data.themes.nodes[1].value : data.themes.nodes[0].value
    );
  }, [darkMode, loading, data]);

  if (loading) return <></>;

  featureFlagManager.initialize(data.featureFlags.nodes);

  localStorage.setItem('REFRESH_DATE', data.lastUpdated);
  localStorage.setItem('SM_SCREEN', '600px');
  localStorage.setItem('MD_SCREEN', '900px');
  localStorage.setItem('LG_SCREEN', '1200px');
  localStorage.setItem('XL_SCREEN', '1536px');

  if (featureFlagManager.featureEnabled(FLAG.MAINTENANCE)) {
    return <Maintenance />;
  }

  const handleThemeToggle = () => {
    setDarkMode(!darkMode);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <LoginModalProvider>
            <Header
              id="header"
              darkMode={darkMode}
              handleThemeToggle={handleThemeToggle}
            />
            <main style={defaultStyle}>
              <Suspense fallback={<ModalSpinner />}>
                <ScrollToTop />
                <Outlet />
              </Suspense>
            </main>
            <Footer />
          </LoginModalProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

function PrivateRoute() {
  const { token } = useToken();

  if (!token && featureFlagManager.featureEnabled(FLAG.INTERNAL_ONLY)) {
    let adminRoute = '/?login=admin';
    return <Navigate to={adminRoute} />;
  }

  return <Outlet />;
}

function AdminPrivateRoute() {
  const { token } = useToken();

  if (!token) {
    let adminRoute = '/?login=admin';
    return <Navigate to={adminRoute} />;
  }
  if (token && !token.admin) {
    return <Navigate to={`${ROUTE.HOME}`} />;
  }

  return <Outlet />;
}

function FavouritesPrivateRoute() {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    return <Navigate to={`${ROUTE.HOME}`} />;
  }

  return <Outlet />;
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<BaseApp />}>
      <Route element={<PrivateRoute />}>
        <Route
          path={`/${ROUTE.SKILLS_EXPLORER}/*`}
          element={<SkillsExplorerApp />}
        />

        <Route path={`/${ROUTE.EXPLORE}/*`} element={<ExploreScene />} />
        <Route path={`/${ROUTE.GROWTH}/*`} element={<GrowthScene />} />
        <Route path={`/${ROUTE.DEMAND}/*`} element={<DemandScene />} />
        <Route path={`/${ROUTE.TRAINING}/*`} element={<TrainingScene />} />

        <Route
          path={`/${ROUTE.WORKING_IN_ALBERTA}/*`}
          element={<WorkingInAlbertaApp />}
        />
        <Route
          path={`/${ROUTE.INTEREST_FILTER}/*`}
          element={<InterestFilterApp />}
        />
        <Route element={<AdminPrivateRoute />}>
          <Route path={`/${ROUTE.ADMIN}/*`} element={<AdminHomeScene />} />
        </Route>
        <Route element={<FavouritesPrivateRoute />}>
          <Route
            path={`/${ROUTE.FAVOURITES}/*`}
            element={<FavouritesScene />}
          />
        </Route>
        <Route
          path={`/${ROUTE.PRIVACY}/*`}
          element={<PrivacyStatement modal={false} />}
        />
        <Route
          path={`/${ROUTE.TERMS}/*`}
          element={<TermsOfUse modal={false} />}
        />
        <Route path={`/${ROUTE.HOME}/*`} element={<HomeApp />} />
        <Route path={`/${ROUTE.ABOUT}/*`} element={<AboutScene />} />
        <Route path={`/${ROUTE.RELEASE}/*`} element={<ReleaseNotesPage />} />
      </Route>
      <Route
        path="/*"
        element={<LandingScene mapImageSource={mapImageSource} />}
      />
      <Route path={`/${ROUTE.LOGIN}/*`} element={<Login formType="login" />} />
      <Route
        path={`/${ROUTE.REGISTER}/*`}
        element={<Login formType="register" />}
      />
      <Route
        path={`/${ROUTE.FORGOT_PASSWORD}/*`}
        element={<Login formType="forgot-password" />}
      />
      <Route path={`/${ROUTE.RESET_PASSWORD}/*`} element={<ResetPassword />} />
    </Route>
  ),
  {
    basename: `${PUBLIC_PATH}`,
  }
);

const App = () => {
  ReactGA.initialize('G-C9549S3S8M');
  const { loading } = useContext(AuthContext);

  if (loading) return <></>;

  let token = '';
  const client = createApolloClient(token);

  return (
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  );
};

export default App;
